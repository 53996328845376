body {
  margin: 0;
  background-color: black;
}
p {
  margin-block-start: 0em;
  margin-block-end: 0em;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-block-start: 0em;
  margin-block-end: 0em;
}
a {
  text-decoration: none;
}
button {
  /* antd button */
  text-transform: unset !important;
}

/* --------- tap highlight in mobile --------- */
html {
  -webkit-tap-highlight-color: transparent;
}

/* --------- aos override --------- */
html:not(.no-js) [data-aos="fade-up"] {
  transform: translate3d(0, 20px, 0);
}
html:not(.no-js) [data-aos="fade-left"] {
  transform: translate3d(40px, 0, 0);
}
