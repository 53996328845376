/* --------- global css --------- */
.section-title {
  color: #c2c2c2;
  font-size: 35px;
  font-weight: 400;
  text-align: center;
  z-index: 1;
}

@media (max-width: 768px) {
  .section-title {
    font-size: 27px;
    font-weight: 400;
  }
}
